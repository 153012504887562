import React from "react";
import { useEffect } from "react";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import gauthmath_img from "../img/gauthmath.webp";
import popjam from "../img/popjam.webp";
import { Helmet } from "react-helmet-async";
export const Brands = () => {
  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>Brands - Splashy Studio</title>
      <meta name="description" content="Splashy Studio is connecting brands with players around the world through in-game advertisements and collaborations to help them grow!"/>
      <link rel="canonical" href="/brands"/>
    </Helmet>
      <NavBar />
      <hr className="border-under opacity-50 " />
      <section id="hiring" className=" bg-backgroundBlue  bg-constellation">
        <div className="  py-8 lg:py-8 px-4 mx-auto ">
          <div className="   flex flex-col items-center justify-center">
            <h2 className="  mb-4 max-w-5xl text-6xl tracking-tight font-body font-black text-center text-white   ">
              Connecting Brands with Players Around the World!
            </h2>
            <p className="  mb-2 font-body font-normal text-center md:px-2 px-10  text-gray-300 max-w-xl  text-xl">
              We've partnered with brands through in-game advertisements and
              collaborations to help them grow!
            </p>
          </div>
        </div>
        <hr className="border-under opacity-50 block" />
        <section className="bg-bodyBlue py-8">
          <section id="game1" className="   ">
            <div className="   py-8 max-w-6xl container flex flex-col items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
              <div className="w-80  flex flex-col md:mr-14 mr-0   ">
                <div className="">
                  <div className="  text-center md:text-left">
                    <p className="font-extrabold  md:mt-0 max-w-md text-4xl font-body  md:text-5xl text-white">
                      Gauthmath
                    </p>
                  </div>

                  <p className="font-body text-center text-gray-300 text-base md:text-left mt-2">
                    In 2022 we partnered with the AI math solver app{" "}
                    <a
                      href="https://www.gauthmath.com/en"
                      target="_blank"
                      className="hover:text-red-600  font-body font-extrabold"
                    >
                      Gauthmath
                    </a>{" "}
                    to create a fun problem-solving minigame that players could
                    use the app to complete and obtain an exclusive reward!
                  </p>
                </div>
              </div>
              <div className="  ">
                <div className="mt-5 md:mt-0">
                  <img
                    className="md:mt-0  rounded-xl shadow-lg w-80 inline-block pointer-events-none "
                    src={gauthmath_img}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="game1" className="   ">
            <div className="   py-5 max-w-6xl container flex flex-col items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
              <div className="w-80  flex flex-col md:mr-14 mr-0   ">
                <div className="">
                  <div className="  text-center md:text-left">
                    <p className="font-extrabold  md:mt-0 max-w-md text-4xl font-body  md:text-5xl text-white">
                      PopJam
                    </p>
                  </div>

                  <p className="font-body text-center text-gray-300 text-base md:text-left mt-2">
                    We've worked with SuperAwesome - an Epic Games Company - to
                    create a kid friendly community of over 6,000 fans on the{" "}
                    <a
                      href="https://www.popjam.com/"
                      target="_blank"
                      className="hover:text-purple-500  font-body font-extrabold"
                    >
                      PopJam
                    </a>{" "}
                    app!
                  </p>
                </div>
              </div>
              <div className="  ">
                <div className="mt-5 md:mt-0">
                  <img
                    className="md:mt-0  rounded-xl shadow-lg w-80 inline-block pointer-events-none "
                    src={popjam}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
      <div className="  py-8 px-4 mx-auto bg-gradient-to-b from-bodyBlue  to-headerBlue">
        <div className="   flex flex-col items-center justify-center">
          <h2 className="   max-w-3xl text-5xl tracking-tight font-body font-black text-center text-white   ">
            Are You a Brand Interested in Collaborating?
            <a className="text-brightBlue" href="mailto:splashystudio-brands@outlook.com">
              {" "}
              Email Us!
            </a>
          </h2>
        </div>
      </div>
      <Footer />
    </>
  );
};
