import React from 'react'
import { NavBar } from '../components/NavBar'
import { Contact } from '../components/Contact'
import { Footer } from '../components/Footer'
import { useEffect } from 'react'
import { Helmet } from "react-helmet-async";
export const Careers = () => {

  
    useEffect(() => {
      
      window.scrollTo(0, 0);
    }, []);
  

  return (
    <>
    <Helmet>
      <title>Careers - Splashy Studio</title>
      <meta name="description" content="Unleash your creativity at Splashy Studio! We're looking for developers with experience in scripting, modeling, or graphic design!"/>
      <link rel="canonical" href="/careers"/>
    </Helmet>
    <NavBar/>
    <Contact/>
    <Footer/>
    </>
  )
}
