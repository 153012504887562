
import ReactGA from 'react-ga'
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Careers } from "./pages/Careers";
import { Brands } from './pages/Brands';
import { NotFound } from './pages/NotFound';
import { Boost } from './pages/Boost';

import {Test} from "./pages/Test";

const TRACKNG_ID = "UA-253008547-1";
ReactGA.initialize(TRACKNG_ID);

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/careers" element={<Careers />}/>
      <Route path="/brands" element={<Brands />}/>
      <Route path="/boost" element={<Boost />}/>
     
      <Route path="*" element={<NotFound/>}/>
      
    </Routes>
  );
}
