import React from "react";

export const Supporters = () => {
  return (
    <section className="bg-bodyBlue pb-16 pt-6">
      <div className="  py-8 lg:py-8 px-4 mx-auto max-w-screen-md">
        <div className="   flex flex-col max-w-6xl">
          <h2 className="mb-4 text-5xl tracking-tight font-body font-black text-center text-white  ">
            We Strive to Provide the Perfect Player Experience!
          </h2>
          <h3 className="mb-10 font-body text-center text-gray-300   sm:text-xl">
            Take a look at what our players are saying:
          </h3>
          <hr className=" border-1 border-gray-500 " />
          <div className=" px-4 max-w-full  ">
            <div className="flex items-center mb-4 space-x-4 mt-3">
              <img
                className="w-14 h-14 rounded-full bg-gray-600"
                src="https://tr.rbxcdn.com/7550296a5cf0d7cd343d705bbababfdc/150/150/AvatarHeadshot/Png"
                alt="Roblox Player"
              />
              <div className="space-y-1 font-body   ">
                <p className="text-white">
                Imgonnaeatnuggets{" "}
                  <span className="text-gray-400 text-sm  font-body ">- Jan 6, 2023</span>{" "}
                  <p className="font-normal block  text-gray-300  font-body " >
                    Fantastic, nice game!! The stages are so good!
                  </p>
                </p>
              </div>
            </div>
          </div>
          <hr className=" border-1 border-gray-500 " />
          <div className="   px-4  max-w-full ">
            <div className="  flex items-center mb-4 space-x-4 mt-3">
              <img
                className="w-14 h-14 rounded-full bg-gray-600"
                src="https://tr.rbxcdn.com/3adaea0c9f57c350963e60af9b09c046/150/150/AvatarHeadshot/Png"
                alt="Roblox Player"
              />
              <div className="space-y-1 font-medium   font-body ">
                <p className="text-white">
                Balibahu1{" "}
                  <span className="text-gray-400 text-sm">- Dec 27, 2022</span>{" "}
                  <p className="font-normal block  text-gray-300  font-body " >
                    thank you for making these games I really enjoyed them with the admin😇
                  </p>
                </p>
              </div>
            </div>
          </div>
          <hr className=" border-1 border-gray-500 " />
          <div className=" px-4 max-w-full  ">
            <div className="flex items-center mb-4 space-x-4 mt-3">
              <img
                className="w-14 h-14 rounded-full bg-gray-600"
                src="https://tr.rbxcdn.com/df7cc1479ba7c438e50556c386b9ab21/150/150/AvatarHeadshot/Png"
                alt="Roblox Player"
              />
              <div className="space-y-1 font-medium   font-body ">
                <p className="text-white">
                  Reese{" "}
                  <span className="text-gray-400 text-sm font-body ">- Dec 26, 2022</span>{" "}
                  <p className="font-normal block  text-gray-300  font-body " >
                    Hi just wanted to say you make awesome games! Keep up the
                    good work!
                  </p>
                </p>
              </div>
            </div>
          </div>
          <hr className=" border-1 border-gray-500 " />
          
          <div className=" px-4 max-w-full ">
            <div className="flex items-center mb-4 space-x-4 mt-3">
              <img
                className="w-14 h-14 rounded-full bg-gray-600"
                src="https://tr.rbxcdn.com/a9162f10a576b21ea079cba4a91dee53/150/150/AvatarHeadshot/Png"
                alt="Roblox Player"
              />
              <div className="space-y-1 font-medium   font-body ">
                <p className="text-white">
                LetmetypeausernameAA{" "}
                  <span className="text-gray-400 text-sm">- Dec 24, 2022</span>{" "}
                  <p className="font-normal block  text-gray-300 font-body " >
                    I love your obby! It's super fun and easy
                  </p>
                </p>
              </div>
            </div>
          </div>
          <hr className=" border-1 border-gray-500 " />
          <div className=" px-4 max-w-full  ">
            <div className="flex items-center mb-4 space-x-4 mt-3">
              <img
                className="w-14 h-14 rounded-full bg-gray-600"
                src="https://tr.rbxcdn.com/052b14338c6ffec22cd616daed9eb259/150/150/AvatarHeadshot/Png"
                alt="Roblox Player"
              />
              <div className="space-y-1 font-body   ">
                <p className="text-white">
                Tupatrona_insanxXD12{" "}
                  <span className="text-gray-400 text-sm  font-body ">- Dec 23, 2022</span>{" "}
                  <p className="font-normal block  text-gray-300  font-body " >
                    :o I love your game *proceeds to play it again*
                  </p>
                </p>
              </div>
            </div>
          </div>
          <hr className=" border-1 border-gray-500 " />
          
          
        </div>
      </div>
    </section>
  );
};
