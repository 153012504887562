import React, { useState } from "react";
import player_sc from '../img/player.jpg'
import { GrowDiscordService } from "../services/GrowDiscordService";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
export const initialFormState = {

    data: {
      username: "",
      discord: "",
      gameLink: "",
      
    },
    error: {},
  
}

export const GrowContact = () => {
  const [formData, setFormData] = useState(initialFormState);

 const setDynamicFormData = (name,value) => {

    setFormData({
      data: {
        ...formData.data,
        [name]: value,
      },
      error: {},
    });
  };

  const { Send } = GrowDiscordService(setFormData);

  const PostToDiscord = () => {
    const description = Object.entries(formData.data).map((d) => `${d[0]} : ${d[1]}`).join("\n")
    //console.log(description);
    Send(description);
  }

  return (
    <section  id="hiring" className=" bg-bodyBlue py-4 rounded-xl  border-4 border-brightBlue mx-1  ">
      
      <div className="max-w-2xl container flex items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-between mb-5">
        <form action="#" className=" w-full space-y-4" onSubmit={(e) => {
          e.preventDefault();
          PostToDiscord();
          toast.success("Submitted! Once reviewed, you will be sent a direct message on Discord. Thank you!", {
            position: toast.POSITION.BOTTOM_RIGHT
            
          });
          
        }}><ToastContainer autoClose={8000}/>
        <h2 className=" text-5xl tracking-tight font-body font-black text-center text-white   ">
        Request a Demo
        
          </h2>
          <p className="pb-6 font-body font-normal text-center   text-gray-300  sm:text-xl">
            See what we can do for you! Once we review your form, you'll be contacted directly on Discord
          </p>

          <div className="flex justify-center items-center space-x-10">
            <div className=" w-6/12">
              
              <div className="">
                <div className=" ">
                  
                  <label
                    for="username"
                    className="block mb-2 text-sm font-body text-white  "
                  >
                    Roblox Username
                  </label>
                  <input
                    name="username"
                    type="text"
                    id="text"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDynamicFormData(name,value);
                    }}
                    className="outline-none shadow-sm w-full  border  text-white text-sm rounded-lg  focus:border-brightBlue block  p-2.5  bg-gray-700  border-gray-600  placeholder-gray-400   shadow-sm-light"
                    placeholder="Username"
                    value = {formData.data.username}
                    required
                  />
                </div>
              </div>
            </div>

            <div className=" w-6/12">
              <div>
                <div className=" ">
                  <label
                    for="discord"
                    className="block mb-2 text-sm font-body text-white  "
                  >
                    Discord Tag
                  </label>
                  <input
                    type="text"
                    id="text"
                    name="discord"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDynamicFormData(name,value);
                    }}
                    className="outline-none shadow-sm w-full  border  text-white text-sm rounded-lg  focus:border-brightBlue block  p-2.5  bg-gray-700  border-gray-600  placeholder-gray-400   shadow-sm-light"
                    placeholder="Username#0000"
                    value = {formData.data.discord}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center space-x-10">
            <div className=" w-full">
              <div className="">
                <div className=" ">
                  <label
                    for="gameLink"
                    className="block mb-2 text-sm font-body text-white  "
                  >
                    Game Link
                  </label>
                  <input
                    type="text"
                    id="text"
                    name="gameLink"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDynamicFormData(name,value);
                    }}
                    className="outline-none shadow-sm w-full  border  text-white text-sm rounded-lg  focus:border-brightBlue block  p-2.5  bg-gray-700  border-gray-600  placeholder-gray-400   shadow-sm-light"
                    placeholder="https://www.roblox.com/games/..."
                    value = {formData.data.gameLink}
                    required
                    
                  />
                </div>
              </div>
            </div>

            
          </div>
          

          <div className=" w-full flex justify-center items-center">
            <button
              type="submit"
              className="font-body mt-5 py-3 px-20 font-semibold  text-center  text-white rounded-lg bg-backgroundBrightBlue sm:w-fit hover:bg-backgroundBrighterBlue focus:ring-4 focus:outline-none focus:ring-backgroundBrightBlue   "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
