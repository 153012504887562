import React from 'react'


import studio_screenshot from '../img/dev.webp'
import admin_screensot from '../img/icon_2.webp'
import rainbow_screenshot from '../img/4_thumb.webp'
import easter_screenshot from '../img/blobsim.png'
import bridge_screenshot from '../img/bridge.webp'
import splashy_gfx from '../img/Splash_back_fixed_HR.webp'
import { useState, useEffect } from 'react'
import  Axios  from 'axios'

import {styles} from '../App.css'
function getLastElement(object) {
  const array = [];

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          array.push({ key, value: object[key] });
        }
      }
      //console.log(array[array.length - 1]["value"]);
      return (array[array.length - 1]["value"]);
}


export const Header = () => {

  // const [apiData, setApiData] = useState(null);

  const [total, setTotal] = useState("1000+");
  const [g1plays, setg1plays] = useState(350)
  const [g2plays, setg2plays] = useState(100)
  const [g3plays, setg3plays] = useState(100)
  useEffect(() => {

    Axios.get('https://games.roproxy.com//v1/games?universeIds=2429244677,2171758161,3149100453')
          .then(res2 => {
            console.log(Math.trunc( res2.data.data[0].visits / 1000000));
            const data1 = res2.data.data[0].playing
            const data2 = res2.data.data[1].playing
            const data3 = res2.data.data[2].playing

            const g1p = Math.trunc( res2.data.data[0].visits / 1000000)
            setg1plays(g1p)
            const g2p = Math.trunc( res2.data.data[1].visits / 1000000)
            setg2plays(g2p)
            const g3p = Math.trunc( res2.data.data[2].visits / 1000000)
            setg3plays(g3p)

            
            
            // combine the total values of the two API responses
            const total = data1 + data2 + data3;
            // // update the component's state with the total
            setTotal(total);
            //console.log(total);
          })
          .catch(error => {
            // handle the error here
            setTotal(5348)
            console.error(error);
    });




    
  }, []); // this second argument makes sure that the effect only runs once when the component is mounted
  

  return (
    <>
      <section className=" bg-headerBlue " id="top">
        
        <h1 className="tracking-tight flex justify-center text-5xl font-body text-center md:text-7xl font-black  text-white py-12 md:py-24 px-8">
        Creating Fun Games for Everyone!
        </h1>
        

        <div className="bg-backgroundBlue bg-cover bg-center bg-no-repeat bg-img-folder-svg xl:h-72 lg:h-52 h-32">
         
        </div>
      </section>

      <section id="about" className=" bg-bodyBlue pt-10 pb-10">
        <div className=" py-5 max-w-6xl container flex flex-col items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
          <div className=" flex flex-col">
            <h2 className="font-extrabold md:mt-0 max-w-md text-4xl font-body text-center md:text-5xl md:text-left text-white">
              About Us
            </h2>
            <p className="font-body font-normal w-80 text-center text-gray-300 md:text-left text-base mt-2 mb-4">
              Splashy Studio is a game development group on Roblox passionate about
              creating fun and interactive experiences for players all around
              the world!
            </p>
          </div>

          <div className="">
            <img
              className=" pointer-events-none md:mt-0 md:ml-16 rounded-lg shadow-lg w-80  inline-block"
              src={splashy_gfx}
              alt="Splashy Studio Roblox Logo"
            />
          </div>
        </div>



        <div className="hidden md:flex  max-w-3xl container  items-center px-8 mx-auto space-y-0 md:space-y-0 md:flex-row justify-between mb-5  ">
        <div className="w-36 h-20 bg-headerBlue     rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
              
              <div className=" my-auto   mt-1 ml-1 ">
                <p className="font-body font-extrabold  text-center text-4xl ">500M+</p>
                <p className="font-body font-extrabold  text-center  text-1xl">Total Plays</p>
              </div>

              
              
              
            </div>

            <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p className="font-body font-extrabold  text-center text-4xl ">1.2M+</p>
                <p className="font-body  font-extrabold text-center  text-1xl">Daily Players</p>
              </div>
            </div>

            <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p className="font-body font-extrabold ml-1  text-center text-4xl ">270K+</p>
                <p className="font-body  font-extrabold text-center  text-1xl">Group Members</p>
              </div>
            </div>

            <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p className="font-body font-extrabold  text-center text-4xl ">{total}</p>
                <p className="font-body  font-extrabold text-center  text-1xl">Playing Now</p>
              </div>
            </div>
        </div>

        <div className="md:hidden  max-w-3xl container flex items-center  mx-auto space-y-0 md:space-y-0 md:flex-row justify-between mb-5 flex-col mt-5 ">
        
        <div className=" max-w-3xl container flex items-center px-8 mx-auto  space-x-8 md:flex-row justify-center mb-5  ">

          <div className="w-36 h-20 bg-headerBlue     rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
              <div className=" my-auto   mt-1 ml-1 ">
                <p className="font-body font-extrabold  text-center text-4xl ">500M+</p>
                <p className="font-body  font-extrabold text-center  text-1xl">Total Plays</p>
              </div>
          </div>
{/* join the team */}
            <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p className="font-body font-extrabold  text-center text-4xl ">1.2M+</p>
                <p className="font-body font-extrabold  text-center  text-1xl">Daily Players</p>
              </div>
            </div>
        </div>
        
        <div className=" max-w-3xl container flex items-center px-8 mx-auto  space-x-8 md:flex-row justify-center mb-5  ">

          <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p className="font-body font-extrabold ml-1  text-center text-4xl ">270K+</p>
                <p className="font-body font-extrabold  text-center  text-1xl">Group Members</p>
              </div>
            </div>

            <div className="w-36 h-20 bg-headerBlue    rounded-lg font-body text-white border-2 border-brightBlue space-y-0">
            <div className=" my-auto   mt-1  ">
                <p id="players" className="font-body font-extrabold  text-center text-4xl ">{total}</p>
                <p className="font-body font-extrabold  text-center  text-1xl">Playing Now</p>
              </div>
            </div>
        </div>

        </div>


      </section>
      
      <section id="games" className=" bg-bodyBlue pt-10 ">
        <div className="   py-5 max-w-6xl container flex flex-col-reverse items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
          <div className="md:mt-0 mt-5  flex">
            <img
              className="md:mt-0 mr-2   pointer-events-none rounded-lg shadow-lg w-80 inline-block"
              src={studio_screenshot}
              alt="Roblox Studio view of our game"
            />
          </div>
          <div className="   flex flex-col md:ml-14 w-80">
            <h2 className=" md:mt-0  text-4xl font-body font-extrabold text-center md:text-5xl md:text-left text-white">
              Games
            </h2>
            <p className="font-body font-normal  text-center text-gray-300 md:text-left mt-2">
              Our games have been recommended to millions of players, and
              selected as part of Roblox's Up-and-Coming, Top Engaging, and
              Popular experiences! Check some of them out below!
            </p>
          </div>
        </div>
      </section>

      <section id="game1" className=" bg-bodyBlue  ">
        <div className=" py-5 max-w-6xl container flex flex-col items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
          <div className="w-80 h-56 flex flex-col md:mr-14 mr-0   ">
            <div className="mt-2">
              <div className="  text-center md:text-left">
                <a
                  target="_blank"
                  href="https://www.roblox.com/games/6494527314/Escape-Easy-Admin-Obby"
                  className="font-extrabold  hover:text-hoverBlue md:mt-0 max-w-md text-4xl font-body  md:text-5xl text-white"
                >
                  Escape Easy Admin Obby
                </a>
              </div>

              <p className="font-body text-center text-gray-300 md:text-left mt-2">
                A colorful platformer obstacle course full of stages for players
                to challenge themselves to make it to the end
              </p>
            </div>
          </div>
          <div className="  ">
            <div className="">
              <img
                className="md:mt-0  rounded-lg shadow-lg w-80  inline-block pointer-events-none "
                src={admin_screensot}
                alt="Escape Easy Admin Obby Image"
              />
              <div className="font-extrabold w-40 h-8 bg-headerBlue relative bottom-4 left-36 flex items-center justify-between px-2 rounded-lg font-body text-white border-2 border-brightBlue">
                <span></span>
                <span>{g1plays} Million Visits</span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="game2" className=" bg-bodyBlue">
        <div className=" max-w-6xl container flex flex-col-reverse items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
          <div className="  ">
            <div className="">
              <img
                className="md:mt-0  rounded-lg shadow-lg w-80  inline-block pointer-events-none "
                src={rainbow_screenshot}
                alt="Fall 99999 Rainbow Spiral! Parkour Obby Image"
              />
              <div className="font-extrabold w-40 h-8 bg-headerBlue relative bottom-4 left-20 flex items-center justify-between px-2 rounded-lg font-body text-white border-2 border-brightBlue">
                <span></span>
                <span>{g2plays} Million Visits</span>
                <span></span>
              </div>
            </div>
          </div>
          <div className="w-80 h-56 flex flex-col md:ml-14   ">
            <div className="mt-2">
              <div className=" text-center md:text-left">
                <a
                  target="_blank"
                  href="https://www.roblox.com/games/6011940308/Fall-99999-Rainbow-Spiral-Parkour-Obby"
                  className="font-extrabold hover:text-hoverBlue md:mt-0 max-w-md text-4xl font-body  md:text-5xl  text-white"
                >
                  Rainbow Spiral Obby
                </a>
              </div>

              <p className="font-normal font-body text-center text-gray-300 md:text-left mt-2 ">
                A rainbow themed obstacle course where players start off their
                journey with jump down a huge rainbow spiral
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="game3" className=" bg-bodyBlue pb-12 ">
        <div className=" pt-5 max-w-6xl container flex flex-col items-center px-6 mx-auto space-y-0 md:space-y-0 md:flex-row justify-center">
          <div className="w-80 h-48 flex flex-col md:mr-14   ">
            <div className="mt-0">
              <div className="  text-center md:text-left">
                <a
                  target="_blank"
                  href="https://www.roblox.com/games/8200787440/MEMES-Eat-Blobs-Simulator"
                  className="font-extrabold hover:text-hoverBlue transition  duration-100 md:mt-0 max-w-md text-4xl font-body  md:text-5xl text-white"
                >
                  Eat Blobs Simulator
                </a>
              </div>

              <p className="font-normal font-body text-center text-gray-300 md:text-left mt-2">
                A fun IO style game where players become a blob and compete with eachother to be the biggest in the game!
              </p>
            </div>
          </div>
          <div className="">
            <img
              className="rounded-lg shadow-lg w-80 inline-block pointer-events-none "
              src={easter_screenshot}
              alt="Easter Obby Image "
            />
            <div className="w-40 h-8 bg-headerBlue relative bottom-4 left-4 flex items-center justify-between px-2 rounded-lg font-body font-extrabold text-white border-2 border-brightBlue">
              <span></span>
              <span>{g3plays} Million Visits</span>
              <span></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
