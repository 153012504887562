import React from "react";
import { NavBar } from "../components/NavBar";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Supporters } from "../components/Supporters";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>Splashy Studio - Great Games for Everyone!</title>
      <meta name="description" content="Splashy Studio is a game development group on Roblox aimed at creating fun and interactive experiences for millions of players all around the world!"/>
      <link rel="canonical" href="/"/>
    </Helmet>
      <NavBar />
      <Header />
      <Supporters />
      <Footer />
    </>
  );
};
