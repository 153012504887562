import React from 'react'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react';

export const NotFound = () => {
  useEffect(() => {
      
    document.title = 'Page Not Found - Splashy Studio';
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="flex items-center h-full p-16 bg-headerBlue text-gray-100">
	<div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
		<div class="max-w-md text-center">
			<h2 class="mb-5 font-extrabold font-body text-9xl text-gray-300">
				<span class="sr-only">Error</span>404
			</h2>
			<p class="text-2xl font-semibold font-body md:text-4xl">Sorry, we couldn't find this page</p>
			
            <div className="flex flex-row  items-center justify-center space-x-2">
			
            <NavLink to="/" className='w-auto'>
              <div className="px-2 mt-6  rounded-md  bg-backgroundBrighterBlue text-white hover:text-brightBlue  hover:bg-white   w-auto h-10 flex items-center justify-center transition duration-200">
                <p className="font-body hover:cursor-pointer px-6   text-1xl ">
                  Go Back to Home
                </p>
              </div>
            </NavLink>

            

            </div>
		</div>
	</div>
</section>
  )
}
