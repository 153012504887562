import axios from "axios";
import React from "react";
import { initialFormState } from "../components/Contact";

export const DiscordService = (setFormData) => {

  // {
  //   "content" : "Message Received",
  //   "tts" : false,
  //   "color" : "white",
  //   "embeds" : [{
  //     "title" : "Contact form",
  //     "Description" : "test",
  //     "url" : "https://discord.com/developers/applications",
  //     "thumbnail": {
  //       "url" : "https://e7.pngegg.com/pngimages/236/20/png-clipart-orange-tabby-cat-cat-ginger-animals-cats-thumbnail.png"
  //     }
  //   }]
  // }


  const Send = async (data) => {

    const body = {
      content : "@everyone",
      tts : false,
      color : "white",
      embeds : [{
        title : "Career Application",
        description : data,
        // "url" : "https://discord.com/developers/applications",
        // "thumbnail": {
        //   "url" : "https://e7.pngegg.com/pngimages/236/20/png-clipart-orange-tabby-cat-cat-ginger-animals-cats-thumbnail.png"
        // }
      }]
    }

    try {
      const data = await axios.post(
        "https://discord.com/api/webhooks/1059528654511673425/EDcis_GObB9OvJaAVwr9lhdTl6tlTNzpVCWsBp9Cz-RX3BPtQa1GkZiOr-MkKtLraOgK", body
      );
      
      setFormData(initialFormState);
      
    } catch (error) {
      console.error(error);
    }
  };

  return {
    Send,
  };
};
