import React from "react";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { GrowContact } from "../components/GrowContact";
import { useEffect } from "react";
import promotion from "../img/promotion.svg";
import opti1 from "../img/opti1.svg";
import steps from "../img/steps.svg";
import { Helmet } from "react-helmet-async";
export const Boost = () => {
  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
      <title>Boost Your Game - Splashy Studio</title>
      <meta name="description" content="Increase your game's reach with Splashy Studio! We'll work with you in promoting and optimizing your game. Request a demo today! "/>
      <link rel="canonical" href="/boost"/>
    </Helmet>
      <NavBar />
      <div className="bg-bodyBlue">
        <div className=" pt-20  mx-auto bg-steps    bg-auto bg-repeat-x bg-no-repeat">
          <div className="   flex flex-col items-center justify-center  ">
            <h2 className="px-6  max-w-5xl text-6xl tracking-tight font-body font-black text-center text-white   ">
              Increase Your Game's Reach!
            </h2>
            <h2 className=" px-6 mb-4 max-w-5xl text-6xl tracking-tight font-body font-black text-center text-white   "></h2>

            <div className=" w-full h-38  my-20   ">
              <div className="flex flex-col md:flex-row md:space-x-10 space-y-4 md:space-y-0 items-center justify-center">
                <div className="w-80 h-36 bg-backgroundBlue rounded-xl flex-row space-x-4 flex items-center justify-center border-4 border-brightBlue">
                  <div className="rounded-xl bg-backgroundBrightBlue h-20 w-20 flex items-center justify-center">
                    <img
                      src={promotion}
                      className="w-16 pointer-events-none "
                    ></img>
                  </div>

                  <div className="flex flex-col  h-30 w-48 ">
                    <p className="font-body font-semibold text-white">
                      Game Promotion
                    </p>
                    <p className="font-body font-normal text-white">
                      Cross-promote your games in Splashy Studio experiences
                      with 1.2M+ players everyday
                    </p>
                  </div>
                </div>

                <div className="w-80 h-36 bg-backgroundBlue rounded-xl flex-row space-x-4 flex items-center justify-center border-4 border-brightBlue">
                  <div className="rounded-xl bg-backgroundBrightBlue h-20 w-20 flex items-center justify-center">
                    <img
                      src={opti1}
                      className="w-16  pointer-events-none "
                    ></img>
                  </div>

                  <div className="flex flex-col h-30 w-48">
                    <p className="font-body font-semibold text-white">
                      Game Optimization
                    </p>
                    <p className="font-body font-normal text-white">
                      We'll work with you on optimizing everything from your
                      game's icon to player accessibility
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-bodyBlue to-headerBlue flex flex-row justify-center items-center pb-20  ">
        <GrowContact />
      </div>
      <Footer />
    </>
  );
};
