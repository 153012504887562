import React, { useEffect } from "react";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
export const NavBar = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const toggleMenu = () => {
    // grab everything we need
    // const btn = document.querySelector("button.mobile-menu-button");
    const menu = document.querySelector(".mobile-menu");

    // add event listeners

    menu.classList.toggle("hidden");
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className=" bg-headerBlue sticky top-0 py-0 z-10  backdrop-filter backdrop-blur-lg bg-opacity-90">
      <div className="mx-auto px-4 max-w-7xl">
        <div className="flex justify-between py-3">
          <div className="flex space-x-10">
            <NavLink
              to="/"
              className=" flex items-center px-2 text-white fill-brightBlue"
            >
              <svg
                onClick={handleClick}
                className="w-6 h-6 fill-inherit"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M163.53 21.28c-4.262.042-8.402 1.318-11.936 3.782-14.61 10.188-21.734 41.505 11.03 69.97-.874 22.855 41.786 26.99 24.845-6.657 15.375-44.718-5.465-67.27-23.94-67.094zm255.25 9.564c-6.193.008-12.997 2.884-16.78 8.625-23.15 35.122 1.875 53.016 25.875 14.936 9.863-15.65 1.23-23.575-9.094-23.562zm-41.717 63.468c-63.42 56.883-111.03 138.435-247.688 80.938 18.578 53.492-6.768 71.09-31.313 76.28-8.846-6.104-17.503-14.193-25.374-24.28l-19.282-24.72 2.625 31.25c5.134 61.565 27.576 107.054 64.782 136.376 37.207 29.322 88.146 42.22 149.25 42.22 95.388 0 181.77-60.905 213.563-148l5.344-14.657-15.408 2.186c-5.34.757-9.54.544-12.812-.28l31.688-60.157c-14.324 6.135-29.355 13.593-43.97 20.25 1.73-13.324 5.75-30.14 12.72-50.282l10.312-29.875L446.594 151c-25.463 19.87-52.84 43.613-79.78 63.25-8.948-17.862-7.626-54.075 10.248-119.938zM33.188 139.906c-8.878-.007-18.012 11.466-15.688 22.688 6.156 29.728 35.794 21.19 28.844-7.75-2.593-10.795-7.83-14.934-13.157-14.938zm401.343 44.906c-6.213 24.132-7.675 43.034-3.936 57.844 2.573 10.193 8.258 18.705 16.562 23.5 4.09 2.36 8.58 3.803 13.375 4.47-29.9 20.703-73.522 6.61-53.53-46.72-85.188 114.645-173.707 126.336-202.156 39.125-14.613 86.63-105.328 67.462-125.75-2.342 22.01 18.3 47.298 26.824 70.656 22.25 15.653-3.066 29.977-12.394 40.25-27.438 5.99-8.77 10.622-19.464 13.813-32 13.008 21.732 28.002 35.802 44.812 43.094 22.92 9.942 47.727 6.613 71.688-3.22 39.206-16.086 78.357-49.144 114.218-78.562zm-6 179.688c11.396 7.638 18.095 16.212 18.095 25.125 0 32.772-85.57 59.563-190.375 59.563-104.804 0-189.813-26.79-189.813-59.563 0-8.645 6.17-17.1 16.938-24.53-39.8 13.298-64.844 31.22-64.844 50.81 0 41.02 106.547 74.158 237.72 74.158s237.688-33.137 237.688-74.157c0-19.793-24.892-38.038-65.407-51.406z" />
              </svg>

              <NavLink
                to="/"
                className=" font-extrabold hover:cursor-pointer font-body font-weight-800 text-2xl ml-2"
                onClick={handleClick}
              >
                Splashy Studio
              </NavLink>
            </NavLink>

            
          </div>

          

          <div className=" hidden md:flex items-center space-x-1 py-0">
            <NavLink to="/" onClick={handleClick}>
            <p className="py-2 font-medium font-body hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
              
                Home
              
            </p></NavLink>
<NavLink to="/brands" onClick={handleClick}>
            <p className="py-2  font-medium font-body hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
              
                Brands
              
            </p></NavLink>
            <NavLink to="/careers" onClick={handleClick}>
              <p className="py-2  font-medium font-body  hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
                Careers
              </p>
            </NavLink>
            {/* <p className=" font-body hover:cursor-pointer px-3 text-1.8xl text-white hover:text-brightBlue transition duration-200">
             <a href="/partnership">Partnership</a> 
            </p> */}
            <NavLink to="/boost">
              <div onClick={handleClick} className="ml-3 rounded-full shadow-md  bg-backgroundBrighterBlue text-white hover:text-brightBlue  hover:bg-white   w-auto h-10 flex items-center justify-center transition duration-200">
                <p
                  
                  className=" font-medium font-body hover:cursor-pointer px-6   text-1xl "
                >
                  Boost Your Game
                </p>
              </div>
            </NavLink>
           
          </div>

          {/* <div className=" hidden md:flex items-center space-x-1">
          <a href="https://discord.com/" target="_blank" className="py-0 px-3 text-white fill-white">
            <svg className="w-6 h-6 hover:fill-discordBlurple transition duration-100" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 127.14 96.36">
              <defs>
                <style></style>
              </defs>
              <g id="图层_2" data-name="图层 2">
                <g id="Discord_Logos" data-name="Discord Logos">
                  <g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White">
                    <path className="cls-1"
                      d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <a href="https://twitter.com/splashy_studio" target="_blank" className="py-0 px-3 text-white fill-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-1  hover:fill-twitterBlue transition duration-100"
              viewBox="0 0 24 24">
              <path
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>
          <a href="https://www.youtube.com/@splashy6338/about" className="py-0 px-3 text-white fill-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" target="_blank"
              className="w-8 h-8  hover:fill-pureRed transition duration-100">
              <path
                d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" />
            </svg>
          </a>
        </div> */}

          <div className="md:hidden flex items-center">
            <button className=" mobile-menu-button" onClick={toggleMenu}>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="mobile-menu hidden md:hidden">
        <script src="app.js"></script>
        <NavLink
          to="/"
          onClick={handleClick}
          className="bg-backgroundBlue block py-2 px-4 text-sm text-white text-center font-body hover:bg-backgroundBrightBlue"
        >
          Home
        </NavLink>
        <NavLink
          to="/brands"
          onClick={handleClick}
          className="bg-backgroundBlue block py-2 px-4 text-sm text-white text-center font-body hover:bg-backgroundBrightBlue"
        >
          Brands
        </NavLink>
        <NavLink
          to="/careers"
          onClick={handleClick}
          className="bg-backgroundBlue block py-2 px-4 text-sm text-white text-center font-body hover:bg-backgroundBrightBlue"
        >
          Careers
        </NavLink>
        <NavLink
          to="/boost"
          onClick={handleClick}
          className="bg-backgroundBlue block py-2 px-4 text-sm text-white text-center font-body hover:bg-backgroundBrightBlue"
        >
          Boost Your Game
        </NavLink>
      </div>
    </nav>
  );
};
