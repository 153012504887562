import React from 'react'
import { NavLink } from 'react-router-dom';

export const Footer = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <footer className="bg-headerBlue  pt-8 pb-2 max-w-7xl mx-auto">
    
    <div className='flex flex-row items-center justify-between mx-12'>

    <div className="flex flex-col">
    <p  className="flex items-center justify-center  text-white fill-brightBlue">
        <svg className="w-6 h-6 fill-inherit pointer-events-none" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M163.53 21.28c-4.262.042-8.402 1.318-11.936 3.782-14.61 10.188-21.734 41.505 11.03 69.97-.874 22.855 41.786 26.99 24.845-6.657 15.375-44.718-5.465-67.27-23.94-67.094zm255.25 9.564c-6.193.008-12.997 2.884-16.78 8.625-23.15 35.122 1.875 53.016 25.875 14.936 9.863-15.65 1.23-23.575-9.094-23.562zm-41.717 63.468c-63.42 56.883-111.03 138.435-247.688 80.938 18.578 53.492-6.768 71.09-31.313 76.28-8.846-6.104-17.503-14.193-25.374-24.28l-19.282-24.72 2.625 31.25c5.134 61.565 27.576 107.054 64.782 136.376 37.207 29.322 88.146 42.22 149.25 42.22 95.388 0 181.77-60.905 213.563-148l5.344-14.657-15.408 2.186c-5.34.757-9.54.544-12.812-.28l31.688-60.157c-14.324 6.135-29.355 13.593-43.97 20.25 1.73-13.324 5.75-30.14 12.72-50.282l10.312-29.875L446.594 151c-25.463 19.87-52.84 43.613-79.78 63.25-8.948-17.862-7.626-54.075 10.248-119.938zM33.188 139.906c-8.878-.007-18.012 11.466-15.688 22.688 6.156 29.728 35.794 21.19 28.844-7.75-2.593-10.795-7.83-14.934-13.157-14.938zm401.343 44.906c-6.213 24.132-7.675 43.034-3.936 57.844 2.573 10.193 8.258 18.705 16.562 23.5 4.09 2.36 8.58 3.803 13.375 4.47-29.9 20.703-73.522 6.61-53.53-46.72-85.188 114.645-173.707 126.336-202.156 39.125-14.613 86.63-105.328 67.462-125.75-2.342 22.01 18.3 47.298 26.824 70.656 22.25 15.653-3.066 29.977-12.394 40.25-27.438 5.99-8.77 10.622-19.464 13.813-32 13.008 21.732 28.002 35.802 44.812 43.094 22.92 9.942 47.727 6.613 71.688-3.22 39.206-16.086 78.357-49.144 114.218-78.562zm-6 179.688c11.396 7.638 18.095 16.212 18.095 25.125 0 32.772-85.57 59.563-190.375 59.563-104.804 0-189.813-26.79-189.813-59.563 0-8.645 6.17-17.1 16.938-24.53-39.8 13.298-64.844 31.22-64.844 50.81 0 41.02 106.547 74.158 237.72 74.158s237.688-33.137 237.688-74.157c0-19.793-24.892-38.038-65.407-51.406z" />
        </svg>
        <span className="font-body font-extrabold text-2xl ml-2 onClick={handleClick}">Splashy Studio</span>
      </p>
    </div>


    {/* <div className=" flex-row space-x-10 hidden md:flex">
    <p className="font-body font-normal text-white">Home</p>
    <p className="font-body font-normal text-white">Brands</p>
    <p className="font-body font-normal text-white">Careers</p>
    <p className="font-body font-normal text-white">Boost Your Game</p>
    </div> */}
    
    <div className=" hidden md:flex items-center space-x-1 py-0">
            <NavLink to="/" onClick={handleClick}>
            <p className="py-2 font-medium font-body hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
              
                Home
              
            </p></NavLink>
<NavLink to="/brands" onClick={handleClick}>
            <p className="py-2  font-medium font-body hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
              
                Brands
              
            </p></NavLink>
            <NavLink to="/careers" onClick={handleClick}>
              <p className="py-2  font-medium font-body  hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
                Careers
              </p>
            </NavLink>
            {/* <p className=" font-body hover:cursor-pointer px-3 text-1.8xl text-white hover:text-brightBlue transition duration-200">
             <a href="/partnership">Partnership</a> 
            </p> */}
            <NavLink to="/boost" onClick={handleClick}>
              <p className="py-2  font-medium font-body  hover:cursor-pointer px-3  text-1xl text-white hover:text-brightBlue transition duration-200">
                Boost Your Game
              </p>
            </NavLink>
            
            <a></a>
          </div>

    </div>


    <hr className=" border-1 border-gray-500 mt-6 mx-12" />
    <hr className=" border-1 border-gray-500 mx-12" />

    <div className='flex flex-row items-center justify-between mx-12 pt-4 pb-2'>
    <p className="font-body font-normal text-white">&copy; 2020-2023</p>
    </div>

    {/* <div>
      <p  className="flex items-center justify-center px-2 text-white fill-brightBlue">
        <svg className="w-6 h-6 fill-inherit" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M163.53 21.28c-4.262.042-8.402 1.318-11.936 3.782-14.61 10.188-21.734 41.505 11.03 69.97-.874 22.855 41.786 26.99 24.845-6.657 15.375-44.718-5.465-67.27-23.94-67.094zm255.25 9.564c-6.193.008-12.997 2.884-16.78 8.625-23.15 35.122 1.875 53.016 25.875 14.936 9.863-15.65 1.23-23.575-9.094-23.562zm-41.717 63.468c-63.42 56.883-111.03 138.435-247.688 80.938 18.578 53.492-6.768 71.09-31.313 76.28-8.846-6.104-17.503-14.193-25.374-24.28l-19.282-24.72 2.625 31.25c5.134 61.565 27.576 107.054 64.782 136.376 37.207 29.322 88.146 42.22 149.25 42.22 95.388 0 181.77-60.905 213.563-148l5.344-14.657-15.408 2.186c-5.34.757-9.54.544-12.812-.28l31.688-60.157c-14.324 6.135-29.355 13.593-43.97 20.25 1.73-13.324 5.75-30.14 12.72-50.282l10.312-29.875L446.594 151c-25.463 19.87-52.84 43.613-79.78 63.25-8.948-17.862-7.626-54.075 10.248-119.938zM33.188 139.906c-8.878-.007-18.012 11.466-15.688 22.688 6.156 29.728 35.794 21.19 28.844-7.75-2.593-10.795-7.83-14.934-13.157-14.938zm401.343 44.906c-6.213 24.132-7.675 43.034-3.936 57.844 2.573 10.193 8.258 18.705 16.562 23.5 4.09 2.36 8.58 3.803 13.375 4.47-29.9 20.703-73.522 6.61-53.53-46.72-85.188 114.645-173.707 126.336-202.156 39.125-14.613 86.63-105.328 67.462-125.75-2.342 22.01 18.3 47.298 26.824 70.656 22.25 15.653-3.066 29.977-12.394 40.25-27.438 5.99-8.77 10.622-19.464 13.813-32 13.008 21.732 28.002 35.802 44.812 43.094 22.92 9.942 47.727 6.613 71.688-3.22 39.206-16.086 78.357-49.144 114.218-78.562zm-6 179.688c11.396 7.638 18.095 16.212 18.095 25.125 0 32.772-85.57 59.563-190.375 59.563-104.804 0-189.813-26.79-189.813-59.563 0-8.645 6.17-17.1 16.938-24.53-39.8 13.298-64.844 31.22-64.844 50.81 0 41.02 106.547 74.158 237.72 74.158s237.688-33.137 237.688-74.157c0-19.793-24.892-38.038-65.407-51.406z" />
        </svg>
        <span className="font-body font-extrabold text-2xl ml-2 onClick={handleClick}">Splashy Studio</span>
      </p>
    </div>

    <div>
      <p className=" font-body flex items-center justify-center px-2 text-white fill-brightBlue">

         &copy; 2023
      </p>
    </div> */}

  </footer>
  )
}
